<template>
    <div class="col-12 col-md-10 offset-md-1 produtos-detalhes">
        <div class="row">
            <div class="col-12 col-md-6">
                <ImgProduto :img-bg="false" :url-imagem="item.url_midia" />
            </div>
            <div class="col-12 col-md-6">
                <h1>{{ this.item.descricao }}</h1>
                <h2 class="mt-4" v-html="this.item.informativo_html"></h2>
                <h3 class="mt-4">{{ this.item.valor_unitario.formatado }}</h3>

                <button
                    type="button"
                    @click="adicionarItem"
                    class="btn btn-site btn-lg mt-4"
                >
                    <svg
                        width="23"
                        height="22"
                        viewBox="0 0 23 22"
                        fill="#551600"
                    >
                        <path
                            d="M7.09977 17.6C5.88981 17.6 4.91085 18.59 4.91085 19.8C4.91085 21.01 5.88981 22 7.09977 22C8.30973 22 9.2997 21.01 9.2997 19.8C9.2997 18.59 8.30973 17.6 7.09977 17.6ZM0.5 0V2.2H2.69992L6.65979 10.549L5.17484 13.244C4.99885 13.552 4.89985 13.915 4.89985 14.3C4.89985 15.51 5.88981 16.5 7.09977 16.5H20.2993V14.3H7.56176C7.40776 14.3 7.28677 14.179 7.28677 14.025L7.31977 13.893L8.30973 12.1H16.5044C17.3294 12.1 18.0554 11.649 18.4294 10.967L22.3672 3.828C22.458 3.66013 22.5037 3.47158 22.4998 3.28078C22.4959 3.08998 22.4426 2.90346 22.345 2.73943C22.2475 2.5754 22.1091 2.43947 21.9433 2.34492C21.7776 2.25037 21.5901 2.20044 21.3993 2.2H5.13084L4.09688 0H0.5ZM18.0994 17.6C16.8894 17.6 15.9105 18.59 15.9105 19.8C15.9105 21.01 16.8894 22 18.0994 22C19.3094 22 20.2993 21.01 20.2993 19.8C20.2993 18.59 19.3094 17.6 18.0994 17.6Z"
                            fill="#551600"
                        ></path>
                    </svg>
                     Adicionar Carrinho
                </button>
            </div>
        </div>

        <loading :active="loadingBase"
                 :can-cancel="true"
                 :is-full-page="true"/>


    </div>
</template>

<script>
import Vue from "vue";
import ImgProduto from "../components/ImgProduto.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions, mapState } from "vuex";
import _ from 'lodash'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

export default {
    name: "ProdutoDetalheItem",
    components: { ImgProduto, Loading },
    props: {
        item: Object,
    },

    data(){
        return {
            loadingBase: false
        }
    },

    created() {
        document.title = this.item.descricao + " | " + Vue.prototype.$titulo;
    },

    computed:{
        ...mapState({
            itensCarrinho: (state) => state.carrinho.itens,
        }),
    },


    async mounted(){
        await this.carregarCarrinho()
    },

    methods: {
        ...mapActions(['adicionarItemCarrinho', 'carregarCarrinho']),

        existeItem(id){
            let existe = false

            _.forEach(this.itensCarrinho, (value) => {
                if (value.id == id){
                    existe = true
                }
            })

            return existe
        },

        async adicionarItem() {
            let midia = "";

            if (!this.item.url_midia) {
                midia =
                    "https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg";
            } else {
                midia = this.item.url_midia;
            }

            let produto = {
                id: this.item.id,
                titulo: this.item.descricao,
                qtde: 1,
                descricao: this.item.informativo,
                valor: this.item.valor_unitario.valor,
                url_midia: midia,
            };

            if (!this.existeItem(this.item.id)){
                this.loadingBase = true
                await this.adicionarItemCarrinho(produto)
                this.loadingBase = false
                this.$router.push({name : 'Carrinho', params : {adicionado : true}})
            }else{
                this.$swal('Opss!', 'Você já tem esse item no carrinho', 'warning')
            }

        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.produtos-detalhes {
    background-color: $marrom;
    border-radius: 15px;
    padding: 25px;
    h1 {
        color: $bege;
        font-family: $roboto;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 32px;
    }
    h2 {
        color: $branco;
        font-family: $roboto;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
    }
    h3 {
        color: $amarelo;
        font-family: $roboto;
        font-weight: 900;
        font-style: normal;
        font-size: 41px;
        line-height: 41px;
    }
    .btn-site {
        color: $marrom;
        background-color: $amarelo;
    }
}
@media (max-width: 767.98px) {
    .produtos-detalhes {
        h1 {
            margin-top: 15px;
        }
    }
}
</style>