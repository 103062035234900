<template>
    <div class="img-produto">
        <img
            v-show="!loading && !imgBg"
            :src="url"
            @load="imagemCarregada"
            class="card-img-top"
            alt=""
        />
        <div
            v-show="!loading && imgBg"
            @load="imagemCarregada"
            :style="{ backgroundImage: 'url(' + url + ')' }"
            class="div-bg"
        ></div>

        <Loading v-if="loading"/>
        
    </div>
</template>

<script>
import Loading from "./Loading.vue";
export default {
    components: { Loading },
    name: "ImgProduto",

    data() {
        return {
            loading: true,
            url: "",
        };
    },

    created() {},

    mounted() {
        this.url = this.urlImagem
            ? this.urlImagem
            : "https://bia-truffas.s3.us-west-004.backblazeb2.com/sem_imagem.jpg";
    },

    methods: {
        imagemCarregada() {
            this.loading = false;
        },
    },

    props: {
        urlImagem: String,
        imgBg: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.img-produto {
    .div-bg {
        height: 280px;
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .img-produto {
        .div-bg {
            height: 200px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .img-produto {
        .div-bg {
            height: 200px;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
    .img-produto {
        .div-bg {
            height: 220px;
        }
    }
}

@media (min-width: 1400px) {
}
</style> 