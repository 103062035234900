<template>
    <div class="NotFound" :class="{ 'NotFoundComponet': item }">
        <div class="col-12 col-xl-8 offset-xl-2">
            <div class="text-center mt-5">
                <div class="col">
                    <img
                        class="img-fluid mx-auto d-block"
                        src="@/assets/img/404.png"
                    />
                </div>
                <div class="col mt-3">
                    <h1>404</h1>
                    <h2>
                        Página não encontrada<br />
                        verifique o endereço e tente novamente.
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound",
    props: { item: Boolean },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.NotFound {
    @include background;
    @include form;

    h1 {
        text-transform: uppercase;
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 50px;
        line-height: 50px;
    }

    h2 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 29px;
        line-height: 33px;
    }
}
.NotFoundComponet {
    padding: 0 !important;
    margin-top: -47px;
}

@media (max-width: 767.98px) {
    .NotFound {
        padding: 50px 0 80px;
    }
}
</style>