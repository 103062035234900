<template>
      <div class="d-flex justify-content-center">
            <div
                class="spinner-grow text-light"
                style="width: 3rem; height: 3rem"
                role="status"
            >
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
</template>

<script>
export default {
    name: "Loading",
};
</script>

