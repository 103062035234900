<template>
    <div>
        <component :is="componenteAtual" v-bind:item="item"></component>
    </div>
</template>

<script>
import NotFound from "../views/NotFound.vue";
import Loading from "@/components/Loading.vue";
import ProdutoDetalheItem from "./ProdutoDetalheItem.vue";
import Vue from "vue";
import axiosApi from '@/axios'

export default {
    components: { ProdutoDetalheItem, NotFound, Loading },
    name: "ProdutoDetalhe",
    componenteAtual: '',

    data(){
        return{
            item: {},
            id: '',
            slugCategoria: '',
            urlAmigavel: ''
        }
    },

    created(){
        this.id = this.$route.params.id
        this.slugCategoria = this.$route.params.categoria
        this.urlAmigavel = this.$route.params.produto

        this.componenteAtual = 'Loading'

        axiosApi
            .get(`/produtos/${this.id}`)
            .then((response) => {
                this.item = response.data.item

                if (this.validarProduto(this.id, this.slugCategoria, this.urlAmigavel, this.item.ativo)){
                    this.componenteAtual = 'ProdutoDetalheItem'
                }else{
                    document.title = Vue.prototype.$notFound + ' | ' + Vue.prototype.$titulo
                    this.item = true
                    this.componenteAtual = 'NotFound'
                }
            });
    },

    mounted(){

    },

    methods:{
        validarProduto(id, slug, url, ativo){
            if (!ativo){
                return false
            }else{
                if ((this.item.id == id) && (this.item.slug_categoria == slug) && (this.item.url_amigavel == url)){
                    return true
                }else{
                    return false
                }
            }
        }
    }
};
</script>
